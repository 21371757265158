.loans_table {
  margin: 0;
  padding: 2em 14.6875em;
}
.table_tabs{
    width: 100%;
    display: flex;
}
.table_tab{
    width: 20%;
    height: 2.125em;
    border: 1px solid #BBC8CC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.table_tab:hover{
    transition: 0.3s ease;
    background-color: #e2ecef;
}
.table_tab:first-child{
    border-top-left-radius: 0.5em;
}
.table_tab:last-child{
    border-top-right-radius: 0.5em;
}
.tab_name{
    font-size: 0.75em;
    color: #616060;
    text-align: center;
}
@media only  screen and (min-width: 1920px) {
    .loans_table {
      padding: 2em 29.6875em;
    }
  }
  @media  screen and (max-width: 1200px) {
    .loans_table {
      padding: 2em 8em;
    }
  }
  @media  screen and (max-width: 1024px) {
    .loans_table {
      padding: 2em 1.6875em;
    }
  }
  
@media  screen and (max-width: 768px) {
    .loans_table {
      padding: 1em;
    }
    .table_tab{
        width: 34%;
    }
    .table_tab:first-child{
        display: none;
    }
    .table_tab:last-child{
        display: none;
    }
  }
  
  