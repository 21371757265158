.cards {
  margin: 0;
  padding: 2em 14.6875em;
  padding-bottom: 0;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  top: -9em;
}

.card {
  margin: 0;
  width: 17.75em;
  height: auto;
  background-color: #fff;
  border: 2px solid #e2ecef;
  border-radius: 0.5em;
  margin: 0em 1em;
}

.card_logo_container {
  padding: 1em 1.625em;
  text-align: center;
}

.card_logo {
  max-width: 100%;
  max-height: 100%;
}

.card_title_container {
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  height: max-content;
  background-color: #f0f5f7;
}

.card_title {
  margin: 0;
  font-size: 1em;
  color: #1a1919;
  font-weight: 400;
}

.card_texts_container {
  text-align: center;
  padding: 0 1.625em;
}

.card_up_to {
  font-size: 1.5em;
  line-height: 100%;
  color: #000;
}

.card_from {
  font-size: 1em;
  color: #000;
  margin: 0;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.card_btn {
  width: 100%;
  background-color: #56ccf2;
  border: 2px solid #56ccf2;
  border-radius: 2.125em;
  font-size: 1em;
  margin: 0;
  color: #fff;
  padding: 0.5625em;
  cursor: pointer;
  transition: 0.3s ease;
}

.card_btn:hover {
  color: #56ccf2;
  background-color: #fff;
  transition: 0.3s ease;
}

.card_tin {
  font-size: 0.5625em;
  margin: 0.5625em 0;
}

.card_icon {
  color: #d1d1d1;
  margin-right: 0.4em;
  display: none;
}

@media only screen and (min-width: 1920px) {
  .cards {
    padding: 2em 29.6875em;
  }
}

@media screen and (max-width: 1200px) {
  .cards {
    padding: 2em 8em;
  }
}

@media screen and (max-width: 1024px) {
  .cards {
    padding: 2em 1.6875em;
  }
}

@media only screen and (max-width: 768px) {
  .cards {
    padding: 1.5em 1em;
  }
  .card_icon {
    display: unset;
  }
  .card {
    width: 50%;
    height: auto;
  }

  .card:nth-child(3) {
    display: none;
  }

  .card_title_container {
    display: none;
  }

  .card_texts_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card_up_to,
  .card_title,
  .card_from {
    width: 100%;
    text-align: center;
    font-size: 1em;
    color: #000;
  }
}

@media only screen and (max-width: 480px) {
  .cards {
    padding: 0 0.75em;
  }

  .card {
    width: 50%;
    height: auto;
  }

  .card:nth-child(3) {
    display: none;
  }

  .card_title_container {
    display: none;
  }

  .card_up_to {
    font-size: 1em;
  }

  .card_from {
    font-size: 0.75em;
  }

  .card_btn {
    font-size: 0.75em;
  }

  .card_texts_container {
    padding: 0em 0.625em;
  }
}
@media only screen and (max-width: 280px) {
  .cards {
    padding: 0 0.625em;
    flex-wrap: wrap;
  }

  .card {
    width: 100%;
    height: auto;
    margin: 0.5em 0;
  }

  .card_title_container {
    display: none;
  }

  .card_logo_container {
    text-align: center;
  }

  .card_logo {
    width: 80%;
    height: auto;
  }

  .card_texts_container {
    padding: 0.625em;
  }

  .card_up_to {
    font-size: 1em;
  }

  .card_from {
    font-size: 0.75em;
  }

  .card_btn {
    font-size: 0.75em;
  }

  .card_tin {
    font-size: 0.5em;
  }

  .card_icon {
    display: unset;
  }
}
