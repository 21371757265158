.applications{
    width: 11em;
    height: 11.875em;
    background: #fff;
    border-radius: 0.5em;
}
.nums{
    height: 5.6875em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px #E2ECEF solid;
}
.num_cont{
    margin: 0em 0.25em;
    border-radius: 0.1875em;
    width: 2.0625em;
    height: 2.625em;
    background-color: #56CCF2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.num{
    color: #fff;
    font-size: 1.875em;
    font-weight: bold;
}
.applications_text{
    padding: 1em;
}
.today{
    margin: 0;
    font-size: 0.75em;
    font-weight: 400;
    color: #BDBDBD;
}
.processed{
    margin: 0.5em 0;
    font-size: 0.875em;
    color: #333333;
    font-weight: bold;
}
.circle_check{
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background-color: #56CCF2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 70%;
}