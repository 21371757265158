.buttonUni {
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Добавляем плавный переход для эффекта */
}

.buttonUni:hover {
  background-color: #0056b3;
}

/* Добавляем стили для мобильных устройств */
@media (max-width: 768px) {
  .buttonUni {
    width: 100%; /* Занимаем всю ширину контейнера на мобильных устройствах */
    margin-top: 10px; /* Добавляем отступ сверху для разделения от других элементов */
  }
}

.inputUni-container {
  margin-bottom: 15px;
}

/* Стилизуем текстовый спан */
.inputUni-container span {
  display: block;
  margin-bottom: 5px;
}

/* Стилизуем input */
.inputUni-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Добавляем стили для мобильных устройств */
@media (max-width: 768px) {
  .inputUni-container {
    margin-bottom: 10px; /* Уменьшаем отступ на мобильных устройствах */
  }

  .inputUni-control {
    font-size: 16px; /* Увеличиваем размер шрифта на мобильных устройствах */
  }
}
