.banner {
  margin: 0;
  padding: 3.56253em 14.6875em;
  background-color: #e2ecef;
  width: 100%;
  height: 31.0625em;
  background-image: url("../../assets/girl.png");
  background-repeat: no-repeat;
  background-position: center 30px;
  box-sizing: border-box;
}
.banner_cont {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 8.9375em;
  height: 2.5625em;
}
.banner_title {
  color: #1a1919;
  font-weight: bold;
  margin: 0px;
  margin-top: 1em;
  font-size: 3.125em;
  line-height: 110%;
}
.banner_subtitle {
  margin: 0px;
  font-size: 1.875em;
  font-weight: bold;
  line-height: 100%;
}
.benefit {
  display: flex;
  align-items: center;
  margin-top: 0.875em;
}
.benefit_text {
  margin: 0;
  margin-left: 1.125em;
  font-weight: 400;
  color: #1a1919;
  line-height: 140%;
  font-size: 1.125em;
}

@media only  screen and (min-width: 1920px) {
  .banner {
    padding: 3.56253em 29.6875em;
  }
}
@media  screen and (max-width: 1200px) {
  .banner {
    padding: 3.56253em 8em;
  }
}
@media  screen and (max-width: 1024px) {
  .banner {
    padding: 3.56253em 1.6875em;
  }
}

@media  screen and (max-width: 768px) {
    .banner {
      padding: 1.6875em 1em;
      background-image: none;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .banner {
      padding: 1.6875em 1em;
      background-image: none;

    }
    .application{
        display: none;
    }
    .banner_title{
        margin-top: 0.5em;
    }
  }