.accordion {
  padding: 1em;
  display: none;
}

.accordion__item {
  border-bottom: 1px solid #fff;
  overflow: hidden;
  display: block;
}
.accordion__item:last-child {
  border: none;
}

.accordion__heading {
  color: #fff;
}

.accordion__button {
  background-color: #56ccf2;
  color: #fff;
  font-weight: 600;
  word-wrap: break-word;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 1.2em;
  border: none;
  outline: none;
}

.accordion__button:hover {
  background-color: #4fc5ec;
}
.accordion_icon {
  margin-right: 1em;
}
.accordion__content {
  display: none;
  padding: 0em;
  background-color: #fff;
}

.accordion__content.active {
  display: block;
}

@media only screen and (max-width: 768px) {
  .accordion {
    display: block;
  }
}

.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.accordion__content.active {
  max-height: 1000px;
}
